import { useEventListener } from '@vueuse/core';

const addScrollToTopHandling = () => {
    const scrollWrapper = document.getElementById('scroll-wrp');
    const scrollButton = document.getElementById('scroll-btn');

    if (!scrollWrapper || !scrollButton) {
        return;
    }

    const scrollFunction = () => {
        const hasScrolled = document.body.scrollTop > 20 || document.documentElement.scrollTop > 20;

        scrollWrapper.classList.toggle('active', hasScrolled);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEventListener(scrollButton, 'click', scrollToTop);
    useEventListener(window, 'scroll', () => {
        requestAnimationFrame(scrollFunction);
    }, { passive: true });
};

useEventListener(document, 'DOMContentLoaded', addScrollToTopHandling);
